<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
      drawerSize="extra-large"
    >
      <template v-slot:content>
        <TagDetail :tagId="itemId" v-if="showTagSummary" />
      </template>
    </ui-component-drawer>

    <ui-page-hero id="id_title_channel" :title="'Tags'" />

    <div class="tabs">
      <ul>
        <li :class="{ 'is-active': tagsPageState.selectedTab === 'month' }">
          <a @click="setSelectedTab('month')">
            <span>Tag per month</span>
          </a>
        </li>
        <li :class="{ 'is-active': tagsPageState.selectedTab === 'tags' }">
          <a @click="setSelectedTab('tags')">
            <span>Tags</span>
          </a>
        </li>
      </ul>
    </div>

    <div v-if="tagsPageState.selectedTab === 'tags'">
      <TagList @showTagSummary="setShowTagSummary" />
    </div>

    <div v-if="tagsPageState.selectedTab === 'month'">
      <TagsPerMonth @showTagSummary="setShowTagSummary" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

const TagDetail = () => import('@/components/Tags/TagDetail')
const TagList = () => import('@/components/Tags/TagList')
const TagsPerMonth = () => import('@/components/Tags/TagsPerMonth')

export default {
  components: {
    TagDetail,
    TagList,
    TagsPerMonth,
  },

  props: {},

  data() {
    return {
      showSideDrawer: false,
      drawerTitle: '',
      showTagSummary: false,
    }
  },

  computed: {
    ...mapState('tagStore', ['tagsPageState']),
  },

  created() {},

  methods: {
    ...mapMutations('tagStore', ['setTagsPageState']),

    setSelectedTab(tab) {
      this.tagsPageState.selectedTab = tab
      this.setTagsPageState(this.tagsPageState)
    },

    setShowTagSummary(tag) {
      this.showSideDrawer = true
      this.itemId = tag.Id
      this.drawerTitle = tag.Text

      this.showTagSummary = true
    },

    hideDrawer() {
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.itemId = 0

      this.showTagSummary = false
    },
  },
}
</script>

<style></style>
